var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "page-inner" }, [
        _c("div", { staticClass: "customer__wrapper" }, [
          _c("div", { staticClass: "customer__title" }, [
            _c("div", { key: "2", staticClass: "customer__title-text" }, [
              _vm._v(_vm._s(_vm.customer.businessName))
            ])
          ]),
          _c("div", { staticClass: "customer__main" }, [
            _c("div", { staticClass: "customer__main-col" }, [
              _c("div", { staticClass: "customer__main-title" }, [
                _vm._v("Basic Data")
              ]),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("First Name")
                    ]),
                    _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.firstName))
                    ])
                  ]),
                  _vm.customer.isPublic
                    ? _c("div", { staticClass: "customer__main-cell" }, [
                        _c("div", { staticClass: "cell__title" }, [
                          _vm._v("Middle Initial")
                        ]),
                        _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.middleInitial))
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Last Name")
                    ]),
                    _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.firstName))
                    ])
                  ]),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Nickname")
                    ]),
                    _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.nickName))
                    ])
                  ]),
                  _vm.customer.isPublic
                    ? _c("div", { staticClass: "customer__main-cell" }, [
                        _c("div", { staticClass: "cell__title" }, [
                          _vm._v("Father’s Name")
                        ]),
                        _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.fathersName))
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Main Cell Phone")
                    ]),
                    _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.cellPhone))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Contact E-mail")
                    ]),
                    _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.contactEmail))
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "customer__main-col" }, [
              _c("div", { staticClass: "customer__main-title" }, [
                _vm._v("Business Information")
              ]),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Business Name")
                    ]),
                    _vm.customer.businessName
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.businessName))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ]),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Business Phone")
                    ]),
                    _vm.customer.businessPhone
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.businessPhone))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Accounting E-mail")
                    ]),
                    _vm.customer.accountingEmail
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.accountingEmail))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "customer__main-col" }, [
              _c("div", { staticClass: "customer__main-title" }, [
                _vm._v("Address Information")
              ]),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Address")
                    ]),
                    _vm.customer.address
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.address))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ]),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [_vm._v("City")]),
                    _vm.customer.city
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.city))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Province")
                    ]),
                    _vm.customer.province
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.province))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ]),
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Postal Code")
                    ]),
                    _vm.customer.postalCode
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.postalCode))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "customer__main-col" }, [
              _c("div", { staticClass: "customer__main-title" }, [
                _vm._v("Personal Information")
              ]),
              _c(
                "div",
                { staticClass: "customer__main-row customer__main-row--half" },
                [
                  _c("div", { staticClass: "customer__main-cell" }, [
                    _c("div", { staticClass: "cell__title" }, [
                      _vm._v("Home Phone")
                    ]),
                    _vm.customer.homePhone
                      ? _c("div", { staticClass: "cell__text" }, [
                          _vm._v(_vm._s(_vm.customer.homePhone))
                        ])
                      : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
                  ])
                ]
              ),
              _c("div", { staticClass: "customer__main-row" }, [
                _c("div", { staticClass: "cell__title" }, [_vm._v("Notes")]),
                _vm.customer.homePhone
                  ? _c("div", { staticClass: "cell__text" }, [
                      _vm._v(_vm._s(_vm.customer.notes))
                    ])
                  : _c("div", { staticClass: "cell__text" }, [_vm._v("—")])
              ]),
              _c(
                "div",
                { staticClass: "customer__main-row" },
                [
                  _vm.customer.attachmentPath
                    ? _c("el-image", {
                        staticClass: "customer__image",
                        attrs: {
                          src: _vm.customer.attachmentPath,
                          "preview-src-list": [_vm.customer.attachmentPath],
                          fit: "cover"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }